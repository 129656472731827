import React, { Component } from 'react'
import './index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import About from './subpages/aboutUs';
import Contact from './subpages/contact';
import Events from './subpages/events';
import Photos from './subpages/pGallery';
import Videos from './subpages/vGallery';
import Projects from './subpages/projects';
import Home from './subpages/home';
import UpcomingEvents from './subpages/upcomingEvents';
import GlobalState from './context/globalState';

export default class App extends Component {
  state = {
    location: sessionStorage.getItem('location'),
    ukfbp: sessionStorage.getItem('ukfbp'),
    infbp: sessionStorage.getItem('infbp')
  }

  setLocation = loc => {
    this.setState({
      location: loc
    })
    sessionStorage.setItem('location',loc);
  }

  setIndiaPhotos = photos => {
    this.setState({
      infbp: photos
    })
    sessionStorage.setItem('infbp',photos);
  }

  setUKPhotos = photos => {
    this.setState({
      ukfbp: photos
    })
    sessionStorage.setItem('ukfbp',photos);
  }

  render() {
    return (
      <React.Fragment>
        <GlobalState.Provider value={{
          location: this.state.location,
          setLocation: this.setLocation,
          setIndiaPhotos: this.setIndiaPhotos,
          setUKPhotos: this.setUKPhotos
          }}>
            {this.props.children}
              <Router>
                <div>
                  <Route path="/" component={Home} exact/>
                  <Route path="/aboutus" component={About} exact/>
                  <Route path="/contact" component={Contact} exact/>
                  <Route path="/events" component={Events} exact/>
                  <Route path="/photos" component={Photos} exact/>
                  <Route path="/videos" component={Videos} exact/>
                  <Route path="/projects" component={Projects} exact/>
                  <Route path="/upcoming-events" component={ UpcomingEvents } exact/>
                </div>
              </Router>
        </GlobalState.Provider>
      </React.Fragment>
    );
  }
}