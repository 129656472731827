import React from 'react';
import './carousel.css';
import { Container, Row} from 'react-bootstrap'





function Csel() {


  return (

    <div>
      <Container fluid="true">
          <Row>
            <div className="showcase">
                <div className="video-container"dangerouslySetInnerHTML={{ __html: `
                    <video autoplay loop playsinline playsinline={true} autoPlay={true} muted={true} loop={true} controls={false} id="myVideo"
                      poster="assets/intro-video.png">
                      <source src="assets/intro-video.webm" type="video/webm"/>

                      <source src="assets/intro-video.mp4" type="video/mp4"/>
                    </video>          
                ` }}>

                    
                </div>
            
        

                <div className="heading1">
                        <h1>Welcome to The Sant Zora Singh Lopon Charitable Trust</h1>
              
                <div className='heading2'> <h3>We believe in Humanity</h3></div>
                </div>
                                  
                                
           
                <div className="headingbg"></div>
            </div>
            

         
          

          </Row>
      </Container>
      </div>
  );
}

export default Csel;
