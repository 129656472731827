import React, {  Component } from 'react';
import './navbar.css';
import {  Navbar, Nav, Modal, Button, Container} from 'react-bootstrap';
import PayWithPaypal from './subpages/PayWithPaypal';
import GlobalState from './context/globalState';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

var ppFeature = false;
export default class Nbar extends Component {

  static contextType = GlobalState;
  
  constructor(p) {
    super(p);
    this.state = {
      show: false,
      location: sessionStorage.getItem('location'),
      isCheckedOut: false,
      showPaypalModal: false,
      total: 1.00,
      checkoutList: [
          {'Donation1': 1},
          {'Donation2': 2},
          {'Donation3': 3},
          {'Donation4': 4}
      ],
      showAlertMsg: true,
      alertMsg: '',
      currencyCode: 'GBP',
      flagPhoto: null,
      showContactModal: false,
      tcCheck: false,
      name: null, 
      email: null, 
      phone: null, 
      message: null,
      msgresp: null,
      contactMsg: false,
      showTcModal: false,
      recaptchaVerified: false
    }
    
  }

  componentDidMount() {

    if(sessionStorage.getItem('location')===null || sessionStorage.getItem('location')===undefined) {
       this.getLocation();
    }

  }

  getLocation() {
    fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
        .then(res => res.json())
        .then((data) => {
      
        //set the default country to India for places where we dont have Ashram
        if(data.country_name === 'United States' || data.country_name === 'India' || data.country_name === 'United Kingdom' || data.country_name === 'Canada') {
           this.setState({ location: data.country_name });
           this.context.setLocation(data.country_name);
        } else {
           this.setState({ location: 'India' });
           this.context.setLocation('India');
        }
       
    }).catch(console.log)

  }

  openModal() 
  {
    this.setState({
      show: true
    });
  }
  


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    if(target.type === 'checkbox'){
      this.setState({
        [name]: value
      });
    } else {
      this.setState({
        [name]: value.replace(/<[^>]+>/g, '')
      });
    } 

  }
  recaptchaCallback = (value) => {
    if(value){
      this.setState({
        recaptchaVerified: true
      })
    }
  }
  sendEmail = (e) => {
    e.preventDefault();
    //console.log(this.state.name);
    if(this.state.name && this.state.phone && this.state.email && this.state.message ) {
      const headers = {
        'Content-Type': 'application/json'
      };

      const contactData = {
        name: this.state.name.replace(/<[^>]+>/g, ''),
        phone: this.state.phone.replace(/<[^>]+>/g, ''),
        email: this.state.email.replace(/<[^>]+>/g, ''),
        message: this.state.message.replace(/<[^>]+>/g, '')
      };
      //console.log(contactData);
      axios.post(`https://europe-west2-darbar-darpan.cloudfunctions.net/sendEmail`,  contactData, {headers} )
        .then(res => {
          //console.log(res);
          if(res==="success") this.setState({msgresp: res})
          //console.log(res.data);
        }).catch(console.log)

        this.setState({name: null, phone: null, email: null, message: null, showContactModal: false, recaptchaVerified: false, tcCheck: false});

    }
  }

  render() 
  {
    const { isCheckedOut, total } = this.state;


   
    //if(window.innerWidth < 600) {
    //  eventsLink = <Nav.Link href="/events-mob"><div className='navItem'>Upcoming Events</div></Nav.Link>
    //} else {
    //  eventsLink = <Nav.Link href="/events"><div className='navItem'>Upcoming Events</div></Nav.Link>
    //}
 
    

  return (
    <React.Fragment>
      <GlobalState.Consumer>
        {(context) => (
          <React.Fragment>
       
        

    <Navbar className='nav' inverse="true" fluid="true"  collapseOnSelect="true" expand="lg" variant="light">
        <Navbar.Brand href='/'>
          <img
            alt=""
            src="/assets/logo_icon.png"
            width="80"
            height="80"
            className=" align-center"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
          <Nav.Link href="/"><div className='navItem' onClick={console.log()}>Home</div></Nav.Link>
          <Nav.Link href="/aboutus"><div className='navItem'>About Us</div></Nav.Link>
          <Nav.Link href="/projects"><div className='navItem'>Projects</div></Nav.Link>
          <Nav.Link href="/upcoming-events"><div className='navItem'>Upcoming Events</div></Nav.Link>
          <Nav.Link href="/events"><div className='navItem'>Photo Gallery</div></Nav.Link>
          <Nav.Link href="/videos"><div className='navItem'>Video Gallery</div></Nav.Link>
          <Nav.Link href="/contact"><div className='navItem'>Contact</div></Nav.Link>
          

        </Nav>

      </Navbar.Collapse>


      
  
        <div onClick={() => this.setState({ show: true })}>
        
          { this.state.location ?
              
              <div className="countryLogo" dangerouslySetInnerHTML={{ __html: "<Image className='countryLogo' height='30px' width='30px' src='/assets/"+this.state.location+".png' alt='Logo'/>" }} />
             
            
            :
            <div></div>
          }
          
      </div>
      
       { ppFeature &&
      <Button
onClick={() => {
    this.setState({isCheckedOut: true, showPaypalModal: true})
}}
>Donate</Button>
  }


      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="AZJ65CGJCLDSC" />
      <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
      </form>

      <Button className='contactButton' onClick={() => { this.setState({showContactModal: true})}}>Contact Us</Button>

      <Modal centered='true' className='cselectionModal' id='modal' size="md" show={this.state.show} onHide={() =>  this.setState({ show: false, isCheckedOut: false})} >





        <Modal.Body className='countryModalBody'>
          <img onClick={() => {
            this.setState({ show: false,
              location: "United States"
            })
            this.context.setLocation("United States");
            }
            } className='scountryLogo' height="70px" width="70px" src="./assets/United States.png" alt="Logo" />
          <img onClick={() => {
            this.setState({ show: false,
              location: "United Kingdom"
            })
            this.context.setLocation("United Kingdom");
            }
            }  className='scountryLogo' height="70px" width="70px" src="./assets/United Kingdom.png" alt="Logo" />
          <img onClick={() => {
            this.setState({ show: false,
              location: "Canada"
            })
            this.context.setLocation("Canada");
            }
            }  className='scountryLogo' height="70px" width="70px" src="./assets/Canada.png" alt="Logo" />
          <img onClick={() => {
            this.setState({ show: false,
              location: "India"
            })
            this.context.setLocation("India");
            }
            }  className='scountryLogo' height="70px" width="70px" src="./assets/India.png" alt="Logo" />
        </Modal.Body>

        </Modal>
{ ppFeature &&           
      
      <Modal className='paypalModal' id='modal' size="md" show={this.state.showPaypalModal} onHide={() =>  this.setState({ showPaypalModal: false, isCheckedOut: false})} >




<Modal.Title className='countryModalTitle'>Donate with Paypal</Modal.Title>
<Modal.Body className='countryModalBody'>
  
</Modal.Body>
    
    <div>
    { 
      isCheckedOut  &&

      <PayWithPaypal
          total={total}
          currency={this.state.currencyCode}
          items= {[
            {'name': 1},
            {'name': 2},
            {'name': 3},
            {'name': 4}
        ]}
      />
    
    }
  
  </div>
    
</Modal>
  }



<Modal centered='true' className='contactModal' id='modal' size="lg" show={this.state.showContactModal} onHide={() =>  this.setState({ showContactModal: false})} >




<Modal.Title className='contactModalTitle'>Contact Us
<div onClick={() => {this.setState({ showContactModal: false})}}  className='closeButton'>
    <i className="videoButton material-icons">close</i>     
</div>
</Modal.Title>

<Modal.Body className='contactModalBody'>
    
<Container className='contactContainer' fluid="true" >
<form>
  <div className="form-group">
    <label>Name</label>
    <input name='name' type="name" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter Name" onChange={this.handleInputChange} required/>
    <small id="emailHelp" className="form-text text-muted"></small>
  </div>
 
  <div className="form-group">
    <label>Email address</label>
    <input name='email' type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.handleInputChange} required/>
    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>

  <div className="form-group">
    <label>Phone Number</label>
    <input name='phone' type="phone" className="form-control" id="phone" aria-describedby="emailHelp" placeholder="Enter Phone Number" onChange={this.handleInputChange} required/>
    <small id="emailHelp" className="form-text text-muted"></small>
  </div>

  <div className="form-group">
    <label >Message</label>
    <textarea name='message'   className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={this.handleInputChange} required></textarea>
  </div>
 
  <div className="form-check">
    <input name='tcCheck' type="checkbox" onChange={this.handleInputChange} className="form-check-input" id="exampleCheck1" />
    <label className="form-check-label" >I Agree with the <div className="launchTc">Terms and Conditions</div></label>
  </div>
  <ReCAPTCHA
    sitekey="6LdnIXQaAAAAAPmb1RsUmyaSZx6yMB-pbSI2uXRw"
    onChange={this.recaptchaCallback}
    theme="light"
    size="normal"
  />
  <Button type="submit" disabled={!this.state.tcCheck || !this.state.recaptchaVerified} className={"contactSubmitButton btn-outline-secondary disabled"+!this.state.tcCheck} onClick={this.sendEmail}>Submit</Button>
</form>
</Container>

</Modal.Body>
    
</Modal>


</Navbar>
</React.Fragment>
)}
</GlobalState.Consumer>
    </React.Fragment>
  );
  }

}


