import React, { Component } from 'react';
import Nbar from '../navbar';
import Footer from '../footer';
import './events.css';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "react-loader-spinner";


import { Container, Row, Col, Image } from 'react-bootstrap';

export default class events extends Component {

    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location"),
            showPhotoModal: false,
            selectedPhoto: null,
            selectedAlbum: null,
            selectedPhotoIndex: 0,
            photosToDisplay: 30,
            hasMore: false,
            country: null,
            fbp:[],
            poutput: [],
            requestOut: false,
            loading: false
        };
        this.photos = null;
        this.eventphotogalref = React.createRef();
        this.ukfbp = sessionStorage.getItem("ukfbp");
        this.infbp = sessionStorage.getItem("infbp");
        this.usfbp = sessionStorage.getItem("usfbp");
        this.cafbp = sessionStorage.getItem("cafbp");
        this.page = 0;
        this.cursor = '';
        this.selectedAlbumID = null;
        this.intialMaxPhotos = 20;
        this.curPhotoCaption= '';
        this.backoffTime = {minutes: 0, seconds: 0};
        this.boff = false;

        this.setUk = this.setUk.bind(this);
        this.setCanada = this.setCanada.bind(this);
        this.setUsa = this.setUsa.bind(this);
        this.setIndia = this.setIndia.bind(this);
    }

    componentDidMount(){
    

        
        if(this.props.location.search) {
            switch(this.props.location.search.substring(1)){
                case 'uk':
                    //this.setState({location: "United Kingdom"});
                    this.setUk();
                    break;
                case 'india':
                    //this.setState({location: "India"});
                    this.setIndia();
                    break;
                case 'usa':
                    //this.setState({location: "United States"});
                    this.setUsa();
                    break;
                case 'canada':
                    //this.setState({location: "Canada"});
                    this.setCanada();
                    break;
                default:
            }
        }else {
            switch(this.state.location) {
                case 'United Kingdom': 
                    this.setUk();
                    break;
                case 'United States':
                    this.setUsa();      
                    break;
                case 'India':
                    this.setIndia();
                    break;
                case 'Canada':
                    this.setCanada();
                    break;
                default:

            } 
        }
    }


    async getImg(sessionStorageKey, albumId) {
        //console.log('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId='+albumId+'&limit=20&after=');
        if(!sessionStorage.getItem(sessionStorageKey)) {
            try {
            const response = await fetch('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId='+albumId+'&limit=100&after=')

            const data = await response.json();
            if(data.message){
                //console.log(data.message);
            } else {
                sessionStorage.setItem(sessionStorageKey, JSON.stringify(data[0]));
                this.setState({sessionStorageKey: JSON.stringify(data[0])});   
                
                this.cursor= data[1];
                if(data[0].length < 99){
                    sessionStorage.setItem(sessionStorageKey+"cursor", null);    
                    this.cursor = null;
        
                    
                } else {
                    sessionStorage.setItem(sessionStorageKey+"cursor", JSON.stringify(data[1]));    
                }
            }
            this.setState({loading: true});;
          } catch (error) {
            console.log(error);
          }
            
        }
    }

    addImg(albumId) {
        var after = JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'));
        if(after) {
            //console.log('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId='+albumId+'&limit=20&after='+after.after);
            fetch('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId='+albumId+'&limit=100&after='+after.after)
            .then(res => res.json())
            .then((data) => {
                if(data.message){
                    console.log(data.message);
                } else {
                    //console.log(JSON.parse(sessionStorage.getItem(this.state.selectedAlbum)).concat(data[0]));
                    sessionStorage.setItem(this.state.selectedAlbum, JSON.stringify(JSON.parse(sessionStorage.getItem(this.state.selectedAlbum)).concat(data[0])));                    
                    sessionStorage.setItem('cursor', JSON.stringify(data[1]));
                    var allphotos = JSON.parse(sessionStorage.getItem(this.state.selectedAlbum));               
                    //this.fbp = allphotos;
                    this.cursor= JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'));
                    this.setState({fbp: allphotos});
                }
                this.setState({loading: true});;
            })
            .catch(console.log)
           
        }
    }

    prevPhoto = () => {
        if(this.state.selectedPhotoIndex >= 1) {
            this.curPhotoCaption = this.state.fbp[this.state.selectedPhotoIndex-1].name;

            this.setState({selectedPhoto: this.state.fbp[this.state.selectedPhotoIndex-1], selectedPhotoIndex: this.state.selectedPhotoIndex-1})
        }
        //this.setState({selectedPhoto: JSON.parse(this.state[this.state.selectedAlbum])[this.state.selectedPhotoIndex-1], selectedPhotoIndex: this.state.selectedPhotoIndex-1});           
        
   
    }

    nextPhoto = () => {
        
        if(this.state.selectedPhotoIndex <= this.state.fbp.length) {
            this.curPhotoCaption = this.state.fbp[this.state.selectedPhotoIndex+1].name;
            this.setState({selectedPhoto: this.state.fbp[this.state.selectedPhotoIndex+1], selectedPhotoIndex: this.state.selectedPhotoIndex+1})
        }else if(this.state.selectedPhotoIndex <= this.state.fbp.length) {
            this.loadFunc();
            this.curPhotoCaption = this.state.fbp[this.state.selectedPhotoIndex+1].name;
            this.setState({selectedPhoto: this.state.fbp[this.state.selectedPhotoIndex+1], selectedPhotoIndex: this.state.selectedPhotoIndex+1})
 
        }
        //if(JSON.parse(this.state[this.state.selectedAlbum])[this.state.selectedPhotoIndex+1]!=undefined) {   
        //    this.setState({selectedPhoto: JSON.parse(this.state[this.state.selectedAlbum])[this.state.selectedPhotoIndex+1], selectedPhotoIndex: this.state.selectedPhotoIndex+1});           
        //}
        
    }
    onKeyPressed = (e) => {
        e.preventDefault();

        //console.log(e.key);
        if(e.key === "ArrowRight"){
            this.nextPhoto();
        } else if(e.key === "ArrowLeft"){
            this.prevPhoto();
        }
      }

    async setUsa() {
        
        if(sessionStorage.getItem("usfbp")==="" || sessionStorage.getItem("usfbp")===null || sessionStorage.getItem("usfbp")===undefined) {
            this.setState({loading: true});            
           
            await this.getImg("usfbp","479710209512870");
            this.usfbp = sessionStorage.getItem("usfbp");
        }
        this.selectedAlbumID= '479710209512870';
        
        this.cursor= JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'))
        this.setState({hasMore: true, fbp: JSON.parse(sessionStorage.getItem("usfbp")), country:"United States", photosToDisplay: this.intialMaxPhotos , selected: 0, selectedAlbum: 'usfbp', poutput: []},
        this.updatePhotos());
    }
    async setIndia() {

        if(!sessionStorage.getItem("infbp")) {
            this.setState({loading: true});            
            await this.getImg("infbp","3089045684525311");
            //await this.getImg("infbp","932525746831422");
            this.infbp = sessionStorage.getItem("infbp");
        }
        this.selectedAlbumID= '3089045684525311';
     
        this.cursor= JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'));
        this.setState({hasMore: true, fbp: JSON.parse(sessionStorage.getItem("infbp")), country: "India", photosToDisplay: this.intialMaxPhotos , selected: 0, selectedAlbum: 'infbp', poutput: []},
        this.updatePhotos());
    }
    async setCanada() {
        if(sessionStorage.getItem("cafbp")==="" || sessionStorage.getItem("cafbp")===null || sessionStorage.getItem("cafbp")===undefined) {
            this.setState({loading: true});            
            await this.getImg("cafbp","118554696212011");
            this.infbp = sessionStorage.getItem("cafbp");
        }
        this.selectedAlbumID= '118554696212011';
        this.cursor= JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'))
        this.setState({hasMore: true, fbp: JSON.parse(sessionStorage.getItem("cafbp")), country:"Canada", photosToDisplay: this.intialMaxPhotos , selected: 0, selectedAlbum: 'cafbp', poutput: []},
        this.updatePhotos());
    }
    async setUk(){
        if(!sessionStorage.getItem("ukfbp")) {
            this.setState({loading: true});
            await this.getImg("ukfbp","799839700120828");
            this.ukfbp = sessionStorage.getItem("ukfbp");
        } 
        this.selectedAlbumID= '799839700120828';
        this.cursor= JSON.parse(sessionStorage.getItem(this.state.selectedAlbum+'cursor'));
        this.setState({hasMore: true, fbp: JSON.parse(sessionStorage.getItem("ukfbp")),country: "United Kingdom", photosToDisplay: this.intialMaxPhotos,  selectedAlbum: 'ukfbp', poutput: []});
        this.updatePhotos()
    }

    showModal = (e) => {
        e.preventDefault();

        //console.log(this.fbp[parseInt(e.target.id)].name);
        this.curPhotoCaption = this.state.fbp[parseInt(e.target.id)].name;
        this.setState({showPhotoModal: true, selectedPhoto: this.state.fbp[parseInt(e.target.id)], selectedPhotoIndex: parseInt(e.target.id)})
    }

    async timeout() {
        return new Promise( res => setTimeout(res, 1500) );
    }

    loadFunc = () => {  
        //console.log('Before '+this.boff);
        this.timeout(1000).then((data) => {
            if(!this.boff) {
                //console.log("I'm in")
                if((!this.loading) && this.state.fbp){
                    if (this.state.photosToDisplay+20 <= this.state.fbp.length) {
                        this.setState({loading: true});            this.getImg("cafbp","118554696212011");
                        this.setState({photosToDisplay: this.state.photosToDisplay+20});
                        
                        //console.log("increasing photos count");
                        this.setState({loading: true});;
                        this.updatePhotos();
                    } else if(this.cursor) {
                        this.setState({loading: true});            this.getImg("cafbp","118554696212011");
                        this.addImg(this.selectedAlbumID);
                        this.setState({ photosToDisplay: this.state.photosToDisplay+20}); 
                        this.updatePhotos();
                    }else{
                        this.setState({hasMore: false});
                    }
                }
                
            }
      });

    }

    updatePhotos = () => {
        var oput= [];

        if (this.state.fbp) {
            for(var i=0; i< this.state.photosToDisplay; i++) {
                if(this.state.fbp[i]) {
                    oput.push(<img key={i} id={i} className='eventGalImg' 
                    onClick={(e) => {this.showModal(e)}}
                    src={this.state.fbp[i].images[0].source} alt='Events'/>)
                }
               
            }
            this.setState({hasMore: true, poutput: oput}); 
        }
        
    }

    render() {
        return (
            <div className='contentContainer' >
                <Nbar loc={this.state.location}/>
                <Container fluid="true">
                    <Container>
                    <Row>
                            <Col className='topPad centerText'>
                                <div className="imgCountryHeading">
                                    View Country Specific Gallery
                                </div>
                                <Image className="imgCountry" onClick={this.setUsa} src="assets/United States.png" roundedCircle/>
                                <Image className="imgCountry" onClick={this.setIndia}src="assets/India.png" roundedCircle/>
                                <Image className="imgCountry" onClick={this.setCanada} src="assets/Canada.png" roundedCircle/>
                                <Image className="imgCountry" onClick={this.setUk} src="assets/United Kingdom.png" roundedCircle/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="centerText topHeading headingMargin">
                                    <h4 className="headingText">{this.state.country} Gallery</h4>
                                    <hr className="line"></hr>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                        
                        <Container fluid='true'>
                            <Row>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.state.hasMore}
                                    loader={<div key={0} className="centerText"><Loader
                                    type="ThreeDots"
                                    color="#ff9800"
                                    height={100}
                                    width={100}
                                    timeout={3000} //3 secs
                                /></div>}>
                                    <div className="centerText"><Loader
                                        type="ThreeDots"
                                        color="#ff9800"
                                        height={100}
                                        width={100}
                                        timeout={3000} //3 secs
                                    /></div>
                                    <div className='imageContainer' >
                                        { this.state.fbp ?
                                                this.state.fbp.slice(0, this.state.photosToDisplay).map((item, i) => 
                                                <img key={i} id={i} className='eventGalImg' 
                                                onClick={(e) => {this.showModal(e)}}
                                                src={item.images[0].source} alt="Events"/>
                                                ): <div className="centerText"><Loader
                                                type="ThreeDots"
                                                color="#ff9800"
                                                height={100}
                                                width={100}
                                                timeout={3000} //3 secs
                                            /></div>
                                        }
                                    </div>
                                </InfiniteScroll>
                            </Row>
                        </Container>
                <Footer />
                </Container>
                <Modal centered='true' className='photoModal fade in' id='modal' size="lg" show={this.state.showPhotoModal} onHide={() =>  this.setState({ showPhotoModal: false})} onKeyPress={this.onKeyPressed} >

                        <div onClick={() => {this.setState({ showPhotoModal: false})}}  className='closeButton'>
                            <i className="videoButton material-icons">close</i>     

                        </div>
                        <div onClick={() => {this.prevPhoto() }} className='leftArrow'>
                            <i className="photoButton material-icons">chevron_left</i>     

                        </div>      
                           
                                { (this.state.selectedPhoto && this.state.selectedPhoto) &&
                                <div>
                                    <img className='modalPhoto img-fluid'  src={this.state.selectedPhoto.images[0].source} alt="Current Event"/>
                                    <div className='photoCaption'>
                                        <p>{this.curPhotoCaption}</p>
                                    </div>
                                </div>
                                }
                      
                      <div onClick={() => {this.nextPhoto() }} className='rightArrow'>            
                            <i className="photoButton material-icons">chevron_right</i>
                        </div>
                

                </Modal>
            </div>
        )
    }
}
