import React, { Component } from "react";
import Nbar from '../navbar';
import {Card, Accordion, Container} from 'react-bootstrap';
import Footer from '../footer';
import './upcomingEvents.css';
import getUserLocale from 'get-user-locale';
import InfiniteScroll from 'react-infinite-scroller';



export default class UpcomingEvents extends Component {



  constructor(props) {
    
    super(props);

    
    this.GOOGLE_API_KEY = "AIzaSyBOcwwzInYT7lvegTUTjE3D4LHZbUa1eIw";
    this.CALENDAR_ID = 'c_4h9ins7c6o1pbu1hir8641q34g@group.calendar.google.com';
    
    this.state = {
      events: [],
      allEvents: [],
      firstDate: null,
      lastDate: null,
      description: null,
      hasMore: true
   
    };
  }
  componentDidMount(){
    this.getEvents();
  }


  getEvents() {

    var getEventsURL=null;

        getEventsURL = 'https://www.googleapis.com/calendar/v3/calendars/'+this.CALENDAR_ID+'/events?orderBy=startTime&maxResults=10&singleEvents=true&key='+this.GOOGLE_API_KEY+'&timeMin='+new Date().toISOString();
  
    fetch(getEventsURL)
        .then(res => res.json())
        .then((data) => {

       // console.log(data);

        this.setState({ events: data.items, firstDate: new Date(data.items[0].start.dateTime).toISOString(), lastDate: new Date(data.items[9].start.dateTime).toISOString(),
                    description: data.description});

        //console.log(this.state.firstDate)
        //console.log(this.state.lastDate)
    }).catch(console.log)
  
}
getMoreEvents() {

    var getEventsURL=null;
if (this.state.lastDate != null){
        getEventsURL = 'https://www.googleapis.com/calendar/v3/calendars/'+this.CALENDAR_ID+'/events?orderBy=startTime&maxResults=10&singleEvents=true&key='+this.GOOGLE_API_KEY+'&timeMin='+this.state.lastDate;
  
    fetch(getEventsURL)
        .then(res => res.json())
        .then((data) => {

       // console.log(data);
//console.log(JSON.stringify(this.state.events).concat(JSON.stringify(data.items)));
//var tmp=this.state.events;
//tmp.push(data.items);
this.setState({ events: [...this.state.events, ...data.items], firstDate: new Date(data.items[0].start.dateTime).toISOString(), lastDate: new Date(data.items[9].start.dateTime).toISOString()  });
        //console.log(this.state.firstDate)
        //console.log(this.state.lastDate)
    }).catch(console.log)
}
}
addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  loadFunc = () => {
this.getMoreEvents();
 
    }
  


  render() {

    return (

        <div className='contentContainer ' >
            <Nbar />
            <Container className='upcomingEvents' fluid="true">
            
            
                <div className="centerText topHeading headingMargin">
                    <h4 className="headingText">{this.state.description && this.state.description}</h4>
                    <hr className="line"></hr>

                </div>
                <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadFunc}
                                        hasMore={this.state.hasMore}
                                        loader={<div key={0} className="loader centerText"><img src='/assets/loading.gif' alt="loading..." /></div>}>

                                  
                    <Accordion defaultActiveKey="0">

                        { this.state.events &&
                 
                            this.state.events.map((item, index) => 
                                    <Card key={index}>
                                            <Accordion.Toggle className="accordionItem" as={Card.Header} eventKey={index.toString()}>
                                               <div className='accordionContainer'>
                                                    <div className='accordionTitle'>{item.summary}</div> 
                                                    <div className='accordionDate'>
                                                        {new Date(item.start.dateTime).toDateString()}  @ 
                                                        {new Date(item.start.dateTime).getHours()}:{this.addZero(new Date(item.start.dateTime).getMinutes())}
                                                    </div>
                                                </div>
                                            </Accordion.Toggle>
                                        
                                        <Accordion.Collapse className ='accordionDetails' eventKey={index.toString()}>
                                            <Card.Body>
                                                <div>
                                                <b>Date: </b>{new Date(item.start.dateTime).toDateString()}
                                                
                                                <b><br/><br/>Your Local Time: </b>{new Date(item.start.dateTime).toLocaleTimeString(getUserLocale())}
                                                <b><br/><br/>Location: </b>{item.location}

                                                <b><br/><br/><br/>Description: </b><br /><br />{item.description}
                                                {//new Date(item.start.dateTime).toString()}
  }
                                               
                                                {
                                                    
                                                }
                                                
                                                
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                            
                            )


                        }
                       
                    </Accordion>
            
                    </InfiniteScroll>
             
            </Container>
            <Footer />
        </div>
  )}
}
