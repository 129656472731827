import React, { Component } from 'react'
import './contact.css';
import Nbar from '../navbar';
import Footer from '../footer';
import { Row, Container, Col} from 'react-bootstrap';


export default class contact extends Component {


    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location")
        };

        this.addresses = [
          {
            name: "India (Head Office)",
            address1: "Sant Ashram Lopon",
            address2: "District Moga",
            address3: "Punjab",
            address4: "India",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            flagImage: "india-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6863.085491410506!2d75.338235!3d30.675002000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910a3721a35936d%3A0x48f68d0298a60455!2sSant%20Ashram%20Lopon!5e0!3m2!1sen!2suk!4v1596558213166!5m2!1sen!2suk"
          },
          {
            name: "United Kingdom",
            address1: "Sant Ashram Wolverhampton",
            address2: "101 Woden Road",
            address3: "Park Village",
            address4: "Wolverhampton",
            address5: "WV10 0BB",
            phone: "07544 950001",
            email: "info@santlopon.com",
            flagImage: "uk-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2423.561031370161!2d-2.114001883880334!3d52.59562927982862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48709b3c230ec55b%3A0x502e69647ceb69d6!2sSant%20Ashram!5e0!3m2!1sen!2suk!4v1596558302625!5m2!1sen!2suk"
          },
          {
            name: "Canada",
            address1: "Sant Ashram Ontario",
            address2: "16560 Mclaughlin Road",
            address3: "Caledon",
            address4: "Ontario",
            address5: "L7K1T5",
            phone: "001 4165794284",
            email: "info@santlopon.com",
            flagImage: "canada-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d260574.44253779834!2d-79.74527088100236!3d43.81502208387711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b057a3d9b0c69%3A0x7dbf94feb61ee06c!2s16560%20McLaughlin%20Rd%2C%20Caledon%20Village%2C%20ON%20L7K%201T5%2C%20Canada!5e0!3m2!1sen!2suk!4v1596623193242!5m2!1sen!2suk"
          },
          {
            name: "United States",
            address1: "Sant Ashram Fresno",
            address2: "150 N Brawley Avenue",
            address3: "Fresno",
            address4: "Califorina",
            address5: "93722",
            phone: "001 9168977143",
            email: "info@santlopon.com",
            flagImage: "usa-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10754.112659764205!2d-119.86192668763634!3d36.74136634694889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809466b43b421c39%3A0x4bb651fc457b1900!2s150%20N%20Brawley%20Ave%2C%20Fresno%2C%20CA%2093706%2C%20USA!5e0!3m2!1sen!2suk!4v1596623284079!5m2!1sen!2suk"
          },
          {
            name: "Sant Ashram Sri Nanaksar Takhtupura Sahib",
            address1: "Takhtupura Sahib",
            address2: "Punjab",
            address3: "142039",
            address4: "",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            flagImage: "india-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3434.759005131943!2d75.33105385192663!3d30.5843479112985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910bd5dea565971%3A0x5aff8d79b7e8fbcf!2sSant%20Darbara%20Singh%20Aasharam!5e0!3m2!1sen!2suk!4v1600804520778!5m2!1sen!2suk"
          },
          {
            name: "Sant Ashram Sri Anandpur Sahib",
            address1: "Anandpur Sahib",
            address2: "Punjab",
            address3: "",
            address4: "",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            flagImage: "india-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10301.183148775186!2d76.50022160782775!3d31.223766078263473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39054bdf60cf3b69%3A0x4089438a0dd009f!2sSant%20Ashram%20Lopon%20Anandpur%20Sahib!5e0!3m2!1sen!2suk!4v1600804423592!5m2!1sen!2suk"
          },
          {
            name: "Sant Ashram Jangiana",
            address1: "Alkara Link Road",
            address2: "Jangiana",
            address3: "148102",
            address4: "Punjab",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            flagImage: "india-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.812640072264!2d75.28057271939291!3d30.4826641008312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910bf4152ddc2cb%3A0xc41e763a390a5326!2sSant%20Ashram%20Lopon%20Jangiana!5e0!3m2!1sen!2suk!4v1601289090684!5m2!1sen!2suk"
          },{
            name: "Sant Ashram Jagraon",
            address1: "Heera Bagh",
            address2: "Jagraon",
            address3: "142026",
            address4: "Punjab",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            flagImage: "india-flag",
            mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.9483832462183!2d75.47290152496696!3d30.809773959639845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a77145bfb5d85%3A0xa535a350f7eba081!2sSant%20Ashram%20Lopon.%20Jagraon!5e0!3m2!1sen!2suk!4v1600804320340!5m2!1sen!2suk"
          }
        ]
    }



    componentDidMount(){
        if(sessionStorage.getItem("location")===null || sessionStorage.getItem("location")===undefined)
            {
                this.getLocation();
                console.log("called");
            }
    }

    getLocation() {
        fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
            .then(res => res.json())
            .then((data) => {

            this.setState({ location: data.country_name });
            sessionStorage.setItem("location", data.country_name);
        }).catch(console.log)
    }

    render() {
        return (
            <div className='contentContainer'>
                <Nbar loc={this.state.location}/>
                {/* Contact Us <br /> */}
                {/* {window.sessionStorage.getItem("country")} */}


                {/* <Container fluid className="imgNoSpace silverBack">
                    <Image src="assets/Contact_us_back_image.jpg" fluid></Image>
                </Container> */}
                
                <Container fluid>
                    <Container>




                        <Row>
                            <Col>
                                <div className="centerText topHeading headingMargin">
                                    <h4 className="headingText">contact us</h4>
                                    <hr className="line"></hr>
                                    <p>
                                      Please contact any of our charity offices located in your country. We always try to reply within 24 hours of recieveing an enquiry but in some special circumstances it may take longer to answer. 
                                    </p>
                                    <p>
                                      email us : <a href = "mailto: info@santlopon.com">info@santlopon.com</a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                </Container>
                
                
                {this.addresses.map((item, index) => 
                    <Container>
                      <div class="addressPadding" />
                      <h5 className="countryName">
                        {item.name}
                      </h5>
                        <Row className="marginSpace">
                        

                          <div className="flagDiv"> 
                            <img className="flagImg" src={'/assets/'+item.flagImage+'.png'} alt="Flag"/>
                          </div>
                            <Col sm>
                              
                                
                                <p className="address">
                                    {item.address1}<br/>
                                    {item.address2}<br/>
                                    {item.address3}<br/>
                                    {item.address4}<br/>
                                    {item.address5}
                                    <br/><br/>

                                    {item.phone}<br/>
                                    {item.email}
                                    <br></br>

                                </p>
                              
                            </Col>
                            <Col sm>
                                <div className="mapBorder">
                                  <iframe title={item.address1} src={item.mapUrl}
                                    width="600" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">
                                  </iframe>
                                </div>
                            </Col>
                        </Row>
                        { (index < this.addresses.length-1) && <hr className='lineSep'/>}
                    </Container>
                     
                )}

               

                <Footer />
            </div>
        )
    }
}
