import React, { Component } from 'react'
import {Modal, Button, Container, Row, Col} from 'react-bootstrap';
import './footer.css'
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

export default class Footer extends Component {
    state= {
        uk: {
            name: "United Kingdom",
            address1: "Sant Ashram Wolverhampton",
            address2: "101 Woden Road",
            address3: "Park Village",
            address4: "Wolverhampton",
            address5: "WV10 0BB",
            phone: "07544 950001",
            email: "info@santlopon.com",
            tag: "© "+new Date().getFullYear()+" The Sant Zora Singh Lopon Charitable Trust UK",
            charityReg: "1146568",
            flagImage: "uk-flag"
        },
        india: {
            name: "India (Head Office)",
            address1: "Sant Ashram Lopon",
            address2: "District Moga",
            address3: "Punjab",
            address4: "India",
            address5: "",
            phone: "0091 1636252014",
            email: "info@santlopon.com",
            tag: "© "+new Date().getFullYear()+"  The Sant Zora Singh Lopon Charitable Trust",
            charityReg: null,
            flagImage: "india-flag"
        },
        canada: {
            address1: "Sant Ashram Ontario",
            address2: "16560 Mclaughlin Road",
            address3: "Caledon",
            address4: "Ontario",
            address5: "L7K1T5",
            phone: "001 4165794284",
            email: "info@santlopon.com",
            tag: "© "+new Date().getFullYear()+"  The Sant Zora Singh Lopon Charitable Trust",
            charityReg: null,
            flagImage: "canada-flag"
        },
        usa: {
            name: "United States",
            address1: "Sant Ashram Fresno",
            address2: "150 N Brawley Avenue",
            address3: "Fresno",
            address4: "Califorina",
            address5: "93722",
            phone: "001 9168977143",
            email: "info@santlopon.com",
            tag: "© "+new Date().getFullYear()+"  The Sant Zora Singh Lopon Charitable Trust",
            charityReg: null,
            flagImage: "usa-flag"
        },
        showFeedbackModal: false,
        recaptchaVerified: false,
        message: null
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
      
        if(target.type === 'checkbox'){
          this.setState({
            [name]: value
          });
        } else {
          this.setState({
            [name]: value.replace(/<[^>]+>/g, '')
          });
        } 
    
      }
      recaptchaCallback = (value) => {
        if(value){
            this.setState({
                recaptchaVerified: true
            })
        }else{
            this.setState({
                recaptchaVerified: false
            })
        }
      }
      sendEmail = (e) => {
        e.preventDefault();
        //console.log(this.state.name);
        if(this.state.message ) {
          const headers = {
            'Content-Type': 'application/json'
          };
    
          const contactData = {
            name: "Anonymous",
            phone: "Anonymous",
            email: "Anonymous",
            message: this.state.message.replace(/<[^>]+>/g, '')
          };
          //console.log(contactData);
          axios.post(`https://europe-west2-darbar-darpan.cloudfunctions.net/sendEmail`,  contactData, {headers} )
            .then(res => {
              //console.log(res);
              if(res==="success") this.setState({msgresp: res})
              //console.log(res.data);
            }).catch(console.log)
    
            this.setState({message: null, showFeedbackModal: false, recaptchaVerified: false});
    
        }
      }
    render() {
        var footerCont;
        
            if(sessionStorage.getItem("location")==="United Kingdom"){
                footerCont=this.state.uk
            } else if(sessionStorage.getItem("location")==="United States"){
                footerCont=this.state.usa
            } else if(sessionStorage.getItem("location")==="India"){
                footerCont=this.state.india
            } else if(sessionStorage.getItem("location")==="Canada"){
                footerCont=this.state.canada
            } 
        
        if (!footerCont){
            return(<div></div>);
        }else 
        return (
            
                <div>
                    {/* <div className="fixed-bottom">   */}
                    <div>
                        {/* <Navbar color="dark" bg="dark" variant="dark"> */}
                            <Container fluid={true}>
                                <Row>
                                    
                                    <Col className="footerLeft" s={12} xs={12} md={6} l={6} xl={6} >
                                        
                                        <img className="footerFlagImg" src={'/assets/'+footerCont.flagImage+'.png'} alt="Country Flag"/>
                                        
                                            {footerCont.charityReg &&
                                                <p>Charity Registration No: {footerCont.charityReg}</p>
                                            }
                                        {footerCont.tag}
                                        <div className="feedbackButton">
                                            <Button className='contactButton' onClick={() => { this.setState({showFeedbackModal: true, recaptchaVerified: false})}}>Anonymous Feedback</Button>
                                        </div>
                                    
                                    </Col>
                                    <Col className="footerRight" s={12} xs={12} md={6} l={6} xl={6}>
                                    
                            
                                            <b>{footerCont.address1}</b><br />
                                            {footerCont.address2}<br />
                                            {footerCont.address3}<br />
                                            {footerCont.address4}<br />
                                            {footerCont.address5}<br /><br/>
                                            {footerCont.phone}<br />
                                            {footerCont.email} 
                                        
                                    
                                    </Col>
                                
                                </Row>
                            </Container>
                        {/* </Navbar> */}
                    </div>

                    {/* Start of feedback modal */}
                    <Modal centered='true' className='contactModal' id='modal' size="lg" show={this.state.showFeedbackModal} onHide={() =>  this.setState({ showFeedbackModal: false, recaptchaVerified: false})} >

                        <Modal.Title className='contactModalTitle'>Anonymous Feedback
                            <div onClick={() => {this.setState({ showFeedbackModal: false})}}  className='closeButton'>
                                <i className="videoButton material-icons">close</i>     
                            </div>
                        </Modal.Title>

                        <Modal.Body className='contactModalBody'>
                            <Container className='contactContainer' fluid="true" >
                                <form>
                                    <div className="form-group">
                                        <label >Message</label>
                                        <textarea name='message'   className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={this.handleInputChange} required></textarea>
                                    </div>

                                    <ReCAPTCHA
                                        sitekey="6LdnIXQaAAAAAPmb1RsUmyaSZx6yMB-pbSI2uXRw"
                                        onChange={this.recaptchaCallback}
                                        theme="light"
                                        size="normal"
                                    />
                                    <Button type="submit" disabled={!this.state.recaptchaVerified} className={"contactSubmitButton btn-outline-secondary disabled"+!this.state.recaptchaVerified} onClick={this.sendEmail}>Submit</Button>
                                </form>
                            </Container>
                        </Modal.Body>
                            
                    </Modal>
                </div>
            
        )
    }
}
