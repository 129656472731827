import React, { Component } from 'react'
import Nbar from '../navbar'
import Footer from '../footer'
import './projects.css';
import { Row, Container, Col } from 'react-bootstrap';



export default class projects extends Component {

    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location")
        };
    }


    componentDidMount(){
        if(sessionStorage.getItem("location")===null || sessionStorage.getItem("location")===undefined)
            {
                this.getLocation();
                console.log("called");
            }
    }

    getLocation() {
        fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
            .then(res => res.json())
            .then((data) => {

            this.setState({ location: data.country_name });
            sessionStorage.setItem("location", data.country_name);
        }).catch(console.log)
    }

    render() {
        return (
            <div className='contentContainer'>
                <Nbar loc={this.state.location}/>
                {/* Projects <br />
                {window.sessionStorage.getItem("country")} */}

                <Container>
                    <Row>
                        <Col>
                            <div className="centerText topHeading headingMargin">
                                <h4 className="headingText">our projects</h4>
                                <hr className="line"></hr>
                                {/* <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p> */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="borderRow">
                        <Col sm className="imgNoSpace">
                            <div className="img-helping"></div>
                        </Col>
                        <Col sm className="bg-silver">
                            <div className="topHeading">
                                <h5 className="headingText">Winter campaign</h5>
                                <p>
                                    To help the vulnerable within our community, each year donations are received to help those who are homeless. Our team of volunteers alongside other outreach teams, go out to provide the vulnerable with essential items including blankets, sleeping bags, toiletries and food. Our campaign started in 2015 and is on-going. Volunteer groups cover Wolverhampton, Birmingham, Staffordshire, London, Huddersfield and Swindon.
                                </p>
                                {/* <p>
                                    <Button className="btn-color" variant="warning" type="submit">
                                        Learn more
                                    </Button>
                                </p> */}
                            </div>
                        </Col>

                    </Row>
                    <Row className="borderRow">
                        <Col sm className="bg-silver">
                            <div className="topHeading">
                                <h5 className="headingText">Feeding the vulnerable</h5>
                                <p>
                                On a weekly basis we help provide vulnerable groups with food donations. This has ranged from preparing hot cooked meals every Sunday, donating long lasting dry food and reaching out to those who may be struggling such as refugees or low-income families within Wolverhampton and surrounding areas.
                                </p>
                                {/* <p>
                                    <Button className="btn-color" variant="warning" type="submit">
                                        Learn more
                                    </Button>
                                </p> */}
                            </div>
                        </Col>
                        <Col sm className="imgNoSpace">
                            <div className="img-homeless"></div>
                        </Col>
                    </Row>

                    <Row className="borderRow">
                        <Col sm className="imgNoSpace">
                            <div className="img-environment">&nbsp;</div>
                        </Col>
                        <Col sm className="bg-silver">
                            <div className="topHeading">
                                <h5 className="headingText">Helping the Environment</h5>
                                <p>
                                In 2019 we supported our local councils in Wolverhampton and Huddersfield by organising tree planting events for volunteers to support. The initiative originated from our India Charity team with focus on supporting the environment and tackling global warming.
                                </p>
                                {/* <p>
                                    <Button className="btn-color" variant="warning" type="submit">
                                        Learn more
                                    </Button>
                                </p> */}
                            </div>
                        </Col>
                    </Row>

                     <Row className="borderRow">
                        <Col sm className="bg-silver">
                            <div className="topHeading">
                                <h5 className="headingText">Supporting Children</h5>
                                <p>
                                We are passionate about supporting children and believe that no child should go without.
We have run initiatives such as our Toy Appeal for Birmingham Children’s Hospital, raised money for the Roald Dahl Window at the hospital and have been supporting local charities such as Women and Children’s Aid.

                                </p>
                                {/* <p>
                                    <Button className="btn-color" variant="warning" type="submit">
                                        Learn more
                                    </Button>
                                </p> */}
                            </div>
                        </Col>
                        <Col sm className="imgNoSpace">
                            <div className="img-children"></div>
                        </Col>
                    </Row>                
                    
                    <Row className="borderRow">
                        <Col sm className="imgNoSpace">
                            <div className="img-covid">&nbsp;</div>
                        </Col>
                        <Col sm className="bg-silver">
                            <div className="topHeading">
                                <h5 className="headingText">COVID-19</h5>
                                <p>
                                    During the lockdown volunteers have supported pharmacies with medicine drop-offs, helping the elderly and vulnerable where needed and have continued to provide food to those who are vulnerable. We have helped support other initiatives set up by Reengage, All Saints Church in Wolverhampton and Women and Children’s Aid. We will continue to provide on-going support wherever possible.
                                </p>
                                {/* <p>
                                    <Button className="btn-color" variant="warning" type="submit">
                                        Learn more
                                    </Button>
                                </p> */}
                            </div>
                        </Col>
                    </Row>

                </Container>

                <Footer />
            </div>
        )
    }
}
