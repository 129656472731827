import React, { Component } from 'react';
import Nbar from '../navbar';
import Csel from '../carousel';
import { Container, Row, Col } from 'react-bootstrap';
import './home.css';
import  { Spring  } from 'react-spring/renderprops';


export default class home extends Component {

    constructor(p) {
        super(p);
        this.state = {
            uk: {
               img: [],
               name: [],
               set: false
            },
            in: {
                img: [],
                name: [],
                set: false
             },
             usa: {
                img: [],
                name: [],
                set: false
             },
             ca: {
                img: [],
                name: [],
                set: false
             },
            location: sessionStorage.getItem("location")
        };     
    }

        componentDidMount(){
            this.getImg("uk","2835770566527721");
            this.getImg("in","3271248519625788");
            this.getImg("usa","799199557563932");
            this.getImg("ca", "388026885931456");
            //this.getImg("usa","");
            //if(sessionStorage.getItem("location")=="null")
           // {
               // this.getLocation();
                //console.log("called");
           // }

            
        }

        

        getImg(countryCode,albumId) {
            //fetch('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId=3033889963361646&limit=1&pagination=')
            //3033889963361646 - India
            //2289523224485794 - UK

          
                fetch('https://europe-west2-darbar-darpan.cloudfunctions.net/fbGetPhotos?albumId='+albumId+'&limit=1&after=')
                .then(res => res.json())
                .then((data) => {

                    var img = data[0][0].source
                    var name = data[0][0].name
                    
                    if (!name){
                        name = "The Facebook photo does not include a title. "
                    }

                    //return("{ img: {img}, name: {name}, set: true }");
                    this.setState({[countryCode]: { img: {img}, name: {name}, set: true }})
                    

                
                })
                .catch(console.log)
            
        }

    render() {
  
        return (
            <div className="box">


                <Container fluid="true"> 
                    <Row className='noPadding'>
                        <Col>
                            <Nbar loc={this.state.location}/>
                        </Col>
                    </Row>
                    <Row className='noPadding'>
                        <Col className='paddingTop'><Csel /></Col>
                    </Row>
               
                    {/* { (this.state.uk.set && this.state.in.set) && */}
                    { (this.state.uk.set && this.state.in.set && this.state.usa.set && this.state.ca.set) &&
                            <Row className="eventsOverlay">
                                <Col className="col" xs={12} md={6} lg={3}>
                                    <Spring
                                        
                                        from= {{ opacity:0, top:100 }}
                                        to= {{ opacity: 1, top:0 }}
                                        config={{ delay: 1000, duration: 1000 }}
                                    
                                    >
                                        { props => (
                                        
                                                <div className="tile" style={props}>
                                                     <a href='/events?uk'>
                                                        <img className='eventImage' width='100%' height='100%' src={this.state.uk.img.img} alt="UK Event" />
                                                        <div className='textBG'> </div>
                                                        <div className='text'>
                                                            {this.state.uk.name.name}
                                                        </div>
                                                    </a>
                                                    <div className='tileCaption'>
                                                        UK
                                                    </div>
                                                </div>
                                        )}
                                    </Spring>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <Spring
                                        from= {{ opacity:0, top:100 }}
                                        to= {{ opacity: 1, top: 0 }}
                                        config={{ delay: 1200, duration: 1000 }}
                                    
                                    >
                                    { props => (
                                        <div className="tile" style={props}>
                                             <a href='/events?india'>
                                                <img className='eventImage' width='100%' height='100%' src={this.state.in.img.img} alt="India Event" />
                                                <div className='textBG'> </div>
                                                    <div className='text'>
                                                        {this.state.in.name.name}
                                                    </div>
                                            </a>
                                            <div className='tileCaption'>
                                                
                                                India
                                            </div>
                                        </div>
                                    )}
                                    </Spring>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <Spring
                                        from= {{ opacity:0, top:100 }}
                                        to= {{ opacity: 1, top: 0 }}
                                        config={{ delay: 1400, duration: 1000 }}
                                    
                                    >
                                    { props => (
                                        <div className="tile" style={props}>
                                            <a href='/events?usa'>
                                                <img className='eventImage' width='100%' height='100%' src={this.state.usa.img.img} alt="USA Event" />
                                                <div className='textBG'> </div>
                                                <div className='text'>
                                                    {this.state.usa.name.name}
                                                </div>
                                            </a>
                                            <div className='tileCaption'>
                                                USA
                                            </div>
                                            
                                        </div>
                                    )}
                                    </Spring>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <Spring
                                        from= {{ opacity:0, top:100 }}
                                        to= {{ opacity: 1, top:0 }}
                                        config={{ delay: 1600, duration: 1000 }}
                                    
                                    >
                                    { props => (
                                      
                                        <div className="tile" style={props}>
                                            <a href='/events?canada'>
                                                <img className='eventImage' width='100%' height='100%' src={this.state.ca.img.img} alt="Canada Event"  />
                                                <div className='textBG'> </div>
                                                    <div className='text'>
                                                        {this.state.ca.name.name}
                                                    </div> 
                                                </a>
                                                <div className='tileCaption'>
                                                    Canada
                                                </div>
                                        </div>
                                    
                                    )}
                                    </Spring>
                                </Col>
                            </Row>
                            }
                            <Row>
                        
                            </Row>
                     
                </Container>
                
                
                
            </div>
        )
    }
}
