import React, { Component } from 'react';
import Nbar from '../navbar';
import Footer from '../footer';
import './aboutUs.css';
import { Row, Container, Col } from 'react-bootstrap';



export default class aboutUs extends Component {

    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location")
        };  
    }


    componentDidMount(){
        if(sessionStorage.getItem("location")===null || sessionStorage.getItem("location")===undefined) 
            {
                this.getLocation();
                console.log("called");
            }
    }

    getLocation() {
        fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
            .then(res => res.json())
            .then((data) => {

            this.setState({ location: data.country_name });
            sessionStorage.setItem("location", data.country_name);
        }).catch(console.log)
    }


    render() {
        
        return (
            <div className='contentContainer'>
                <Nbar loc={this.state.location}/>
                
                {/* Projects <br />
                {window.sessionStorage.getItem("country")} */}

                <Container>
                    <Row>
                        <Col>
                            <div className="centerText topHeading headingMargin"> 
                                <h4 className="headingText">Our Mission</h4>
                                <hr className="line"></hr>
                                <p className="aboutParagraph" style={{textAlign: "center"}}>
                                'The creation is in the Creator and the Creator is in the creation.'<br></br><br></br>

                                By being conscious of this knowledge, it is love and  compassion that drives the Sant Zora Singh Charitable Trust to make the wellbeing of humanity its core mission.

                                Through its multitude of projects, we believe that by selfless service and sharing we can help create a balance of harmony, humility and virtousness throughout society.

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="rowSpacing"> 
                                <h4 className="headingText centerText">About</h4>
                                <hr className="line"></hr>
                                <p className="aboutParagraph" style={{textAlign: "justify"}}>
                                The overall purpose of our Trust is to offer a supporting arm in each country to the charitable and religious organisation in Lopon, Punjab, India. 
                                There is a relentless desire to further spread the Sant Jagjit Singh Ji's divine unsurpassed teachings of Guru Nanak Dev Ji and Sikhism for bettering ourselves and to encourage further charitable work. 
                                The following objectives provide a high-level overview of the charity activities. Not only are you able to appreciate the overwhelming achievements but also admire both the history and uplift of humanity over the decades.  
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="rowSpacing">
                        <h5 className="headingText">The Advancement Of The Sikh Religion</h5>
                        <p className="aboutParagraph">
                        Fundamentally the trust revolves around the teachings of Sikhism via the script of the Guru -The Guru Granth Sahib Ji. 
                        The Sikh concepts are and will be preached through religious sermons conducted by Sant Jagjit Singh Ji (currently residing and heading on the divine Lopon head office). 
                        These are further distributed via various media channels including social media, television, Audio, publication (Monthly magazines), and online presence for a worldwide congregation. 
                        Cggv ccvurrently each country charity committee hold monthly Satsang’s (religious gatherings) where the congregation meet and listen to religious sermons. 
                        Daily programmes are held during Sant Jagjit Singh Ji visit each country.
                        </p>
                    </Row>

                    <Row className="rowSpacing">
                        <h5 className="headingText">The Prevention Or Relief of Poverty</h5>
                        <p className="aboutParagraph">
                            The Trust aim is to develop and support the on-going projects which help the poor in each country and in India and provide social assistance, encourage sustainable conversation and promote cultural activites for the long-term benifit of people regardless of their position in the society. 
                            Rather than specialising in one fiel, it seems more appropriate to open to any worthwile project that relieves poverty and results in social uplifting of the people. this is achieved through improving health services, education, housing sanitation and water as well as conservation of the enviorment and cultural programmes. this headquarter in Lopon presently operate a 365 day 24 hour Langer(free communal kitchen) program whereby people from all classes of society receive food and drink.
                            All the preparation, cooking and the washing-up is alos done by voluntary helpers. In support of the above each trust committe in the country hodls langer programes and other initiatives. 

                        </p>
                    </Row>

                    <Row className="rowSpacing">
                        <h5 className="headingText">The Relief of Those In Need By Reason OF Youth, Age, Health, Disability, or Financial Hardship or Other disadvantage</h5>
                        <p className="aboutParagraph">
                        The Trust headquarters have helped countless poverty struck families achieve a better chance of life. 
                        The numerous strategies deployed are paying for weddings, paying for crippling debts and to provide steppingstone career opportunities. 
                        The Trust intends to support this challenging mission with various activities to bring the communities together. 
                        Some of the projects and initiatives are listed in the projects section on this website.
                        </p>
                    </Row>

                    <Row className="rowSpacing">
                        <h5 className="headingText">Advancement of Education</h5>
                        <p className="aboutParagraph">
                        Over the decades, the trust headquarters in India have proudly established numerous education institutions in the Punjab. This remarkable achievement was initiated by the gracious work of Sant Darbara Singh in the 1970's

                            <br></br><br></br>

                            The Sant Darbara Singh College for Women (1971), Sant Darbara Singh College of Education for Women(1976) and the Sant Darbara Singh Kanya Sen. Sec. School(1982) were built with the following objectives:-
                            <br></br><br></br>
                            <ul>Uplift of the society through women education.</ul>
                            <ul>Develop the scene of duty towards less privileged members of the society. Develop dignity of selfless social service.</ul>
                            <ul>Check cultural and moral degradation and including the habit of simple living and high thinking. Develop all facets of human personality.</ul>
                            <ul>Prepare efficent and enlightened teachers wiling to serve the society.</ul>
                           </p>
                    </Row>
                    <Row className="rowSpacing">
                        <h5 className="headingText">Promotion Of Religious And Racial Harmony And Equality and Diversity</h5>
                        <p className="aboutParagraph">
                        Religious and racial harmony is promoted through sermons and preaching the scripts from the Guru - The Guru Granth Sahib Ji. Rejecting the cast system, equality of all people regardless of race, gender, creed and ethnicity is a very strong belief in Sikhism and promoted by the Trust.

                            <br></br><br></br>

                            Sant Jagjit Singh Ji Preaches the importance of equality through sermons (Katha Vichar). Their message and in support with each country committee, through teamwork and generous funding the trust charity supports the community and Gurbani Vichar (spirtual message).                  

                           </p>
                    </Row>
                    <Row className="rowSpacing">
                        <h5 className="headingText">Advancement Of Health For The Public Benefit</h5>
                        <p className="aboutParagraph">
                        The healthcare in Punjab is mostly private whose doors are only open to the affluent. 
                        Inevitably, this means the health and wellbeing of millions of less privileged people become neglected. 
                        This in time forms a vicious circle of further poverty and unacceptable suffering. In recognition, the headquarters is Lopon have previously funded temporary eye camps to help the poor and needy. 
                        Supplying local colleges and schools with basic sport of equipment and encouragement are all on the agenda. In response to COVID 19 pandemic, trust supplied food and essentials for the venerable people in India and in each country with sangat support. 
                        Each charity Trust in the country runs various initiatives locally as well as supporting activities in India for the environment and wellbeing. 

                           </p>
                    </Row>

                </Container>    

                <Footer />
            </div>
        )
    }
}
