import React, { Component } from 'react'
import Nbar from '../navbar'
import Footer from '../footer'
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import './vGallery.css'


export default class vGallery extends Component {

   

    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location"),
            nextVideoPage: null,
            videos: null,
            totalVideos: null,
            items: [],
            hasMore: false,
            videosToDisplay: 10,
            showVideoModal: false,
            curVideoUrl: ''
        };  
    }


    componentDidMount(){
        this.getVideos();
    }

    getVideos() {
        if(sessionStorage.getItem('videos')==="" || sessionStorage.getItem('videos')===null || sessionStorage.getItem('videos')===undefined) {
            axios.get('https://europe-west2-darbar-darpan.cloudfunctions.net/updateYoutubeList?maxResults=50')
            .then(res => {
                
                if(!res.data.error){
                    console.log(res.data.nextPageToken);

                    sessionStorage.setItem('nextVideoPage', JSON.stringify(res.data.nextPageToken));
                    sessionStorage.setItem('videos', JSON.stringify(res.data.items));
                    sessionStorage.setItem('totalVideos', JSON.stringify(res.data.pageInfo.totalResults));
                    this.setState({nextVideoPage: JSON.parse(sessionStorage.getItem('nextVideoPage'))});
                    this.setState({videos: JSON.parse(sessionStorage.getItem('videos'))});
                    this.setState({totalVideos: JSON.parse(sessionStorage.getItem('totalVideos'))});

                    this.setState({hasMore: true})
                    //console.log(this.state.nextVideoPage);
                    //this.setState({sessionStorageKey: JSON.stringify(data[0])});
                    //this.setState({cursor: JSON.stringify(data[1])});
                    //switch(sessionStorage.getItem("location")) {
                        //case 'United Kingdom': this.setUk();
                        //case 'India': this.setIndia();
                    //}
                }else{
                    console.log("Error - maybe limit extended!");
                }

            })
            .catch(console.log)
        }else {
            this.setState({hasMore: true})
            this.setState({nextVideoPage: JSON.parse(sessionStorage.getItem('nextVideoPage'))});
            this.setState({videos: JSON.parse(sessionStorage.getItem('videos'))});
            
        }

    }

    addVideos() {

        axios.get('https://europe-west2-darbar-darpan.cloudfunctions.net/updateYoutubeList?maxResults=50&pageToken='+this.state.nextVideoPage)
        .then((res) => {
            sessionStorage.setItem('nextVideoPage', JSON.stringify(res.data.nextPageToken));
            sessionStorage.setItem('videos', JSON.stringify(JSON.parse(sessionStorage.getItem('videos')).concat(res.data.items)));

            this.setState({nextVideoPage: res.data.nextPageToken});
            this.setState({videos: JSON.parse(sessionStorage.getItem('videos'))});

            this.setState({hasMore: true});

            //console.log(this.state.videos);
            //console.log(this.state.nextVideoPage);
            //this.setState({sessionStorageKey: JSON.stringify(data[0])});
            //this.setState({cursor: JSON.stringify(data[1])});
            //switch(sessionStorage.getItem("location")) {
                //case 'United Kingdom': this.setUk();
                //case 'India': this.setIndia();
            //}

        })
        .catch(console.log)
    }

    loadFunc = () => {
        //var addItems = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];
       //console.log('Load More');


       this.setState({hasMore: false, videosToDisplay: this.state.videosToDisplay+10});
       
        //this.setState({items: [...this.state.items, addItems]});




      
        if(this.state.videos != null) {
                if (this.state.videosToDisplay+10 <= this.state.videos.length) {
                    this.setState({hasMore: false});
                    this.setState({videosToDisplay: this.state.videosToDisplay+10});
                    this.setState({hasMore: true});
                    console.log("increading video count");
                } else {
                        this.setState({hasMore: false});
                        this.addVideos();
                        this.setState({videosToDisplay: this.state.videosToDisplay+10});
                    }
        }




    }
    showModal = (e) => {
        this.setState({ showVideoModal: true, curVideoUrl: "https://www.youtube.com/embed/"+e.target.id});
    }

    exitModal =  () => {
        this.setState({ showVideoModal: false })
    }
    
    render() {


        var poutput = [];
        if (this.state.videos != null) {
            for(var i=0; i< this.state.videosToDisplay; i++) {
                if(this.state.videos[i]) {
                    poutput.push(                         
                        <img key={i} id={this.state.videos[i].id.videoId} className='videoImg' 
                    onClick={this.showModal}
                    src={this.state.videos[i].snippet.thumbnails.high.url} alt="Video Thumbnail"/>)
                }
            }
        }


        return (
            <div className='contentContainer'>
            <Nbar loc={this.state.location}/>
           
                            <div className="topPad centerText topHeading headingMargin"> 
                                <h4 className="headingText">Videos Gallery</h4>
                                <hr className="line"></hr>
                            </div>
                            <div className='centerText videoGalWrapper'>
                                { this.state.videos &&
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadFunc}
                                        hasMore={this.state.hasMore}
                                        loader={<div className="loader centerText"><img src='/assets/loading.gif' alt="loading..." /></div>}>
                                        
                                        { poutput ?
                                            poutput :
                                            <img src='/assets/loading.gif' alt="loading..." />
                                        }
                                    </InfiniteScroll>
                                }
                            </div>
                            
                     
            <Modal centered='true' className='videoModal fade in' id='modal' size="lg" show={this.state.showVideoModal} onHide={() =>  this.setState({ showVideoModal: false})} >

                        <div onClick={() => {this.exitModal()}}  className='closeButton'>
                            <i className="videoButton material-icons">close</i>     

                        </div>      

                                { (this.state.curVideoUrl) ?
                                    <div class="videoWrapper">
                                        <iframe title={this.state.curVideoUrl} className='modalVideo' src={this.state.curVideoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                    :
                                    <img src='./assets/loading.gif' alt="loading..." />

                                }

                </Modal>
            <Footer />
        </div>
        )
    }
}
