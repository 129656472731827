import React, {  useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import './PayWithPaypal.css'


function PayWithPaypal(props){


 
    
        var items = [];
       
        items = props.items;
        const [paidFor, setPaidfor] = useState(false);
        //const [pay, setPay] = useState(false);
        const [error, setError] = useState(null);
        const [amount, setAmount] = useState(null)
        const [msg] = useState([]);
        const paypalRef = useRef();
        
        const re = /^\d+\.?(\d\d)?$/;
        
     
            useEffect(() => {
                
                    window.paypal
                    .Buttons({
                        createOrder: (data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                  amount: {
                                    value: 1
                                  }
                                }]
                              });
                        },
                        onApprove: async (data, actions) => {
                            await actions.order.capture()
                            
                                .then (r => {
                                    setAmount(null);
                                    setPaidfor(true);
                                    //setPay(null);
                                });
                            
                        },
                        onError: err => {
                            setAmount(null);
                            setError(true);
                            console.log(err);
                            //setPay(null);

                            //setMsg("Error with your Donation, please try again!");

                        }

                    }).render(paypalRef.current)
                
            },[items]);
        

        if(paidFor){
         
                return (
                    <div>
                        Thank you for your Donation!
                    </div>
                )
           
                
            
        }
            
        if(error){
         
            return (
                <div>
                    Error, please try again!
                </div>
            )
       
        
        
    }
        return (

            
 
            <div>
                 { !paidFor &&
                <div>
                    <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Amount in GBP.</Form.Label>
                        <Form.Control type="amount" placeholder="Donation Amount"
                            onChange={e => {
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    setAmount(e.target.value)
                                 }else{
                                    setAmount("Invalid Input")
                                 }
                                
                            }}
                        />
                        <Form.Text className="text-muted">
                        Please select your method of donation below.
                        </Form.Text>
                    </Form.Group>
                    </Form>
                            Total Amount: <b>{ amount }</b>
                      
                        <div ref={paypalRef}/>
                        
                    
                </div>
                
               
                
                }  
                

                {
                    msg
                }
                
            </div>
        )
    }

export default PayWithPaypal
