import React, { Component } from 'react'
import Nbar from '../navbar'
import Footer from '../footer'
import { Row, Container, Col } from 'react-bootstrap';

export default class pGallery extends Component {

    constructor(p) {
        super(p);
        this.state = {
            location: sessionStorage.getItem("location")
        };  
    }


    componentDidMount(){
        if(sessionStorage.getItem("location")===null || sessionStorage.getItem("location")===undefined) 
            {
                this.getLocation();
                console.log("called");
            }
    }

    getLocation() {
        fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572')
            .then(res => res.json())
            .then((data) => {

            this.setState({ location: data.country_name });
            sessionStorage.setItem("location", data.country_name);
        }).catch(console.log)
    }

    render() {
        return (
            <div className='contentContainer'>
                <Nbar loc={this.state.location}/>
                <Container fluid="true"> 
                    <Container>
                        <Row>
                            <Col>
                                <div className="centerText topHeading headingMargin"> 
                                    <h4 className="headingText">Photos</h4>
                                    <hr className="line"></hr>
                                    <p>
                                        This is the events page.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>   
                </Container>
                <Footer />
            </div>
        )
    }
}
